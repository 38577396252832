import React, { useState, useEffect, useContext } from "react"
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileCirclePlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import faceIO from "@faceio/fiojs"
import { fioErrCode } from "@faceio/fiojs"
import CredentialContext from "../context/CredentialContext"

export const Credential = ({ handlerChangeInfo, widthVideo, heightMark }) => {

  const { setCredential } = useContext(CredentialContext)

  const faceio = new faceIO('fioaf24a')

  const [ine, setIne] = useState(0)
  const [type, setType] = useState('front')
  const [showWebcam, setShowWebcam] = useState(true)
  const [width, setWidth] = useState(widthVideo)
  const [images, setImages] = useState([])
  const [videoSettings] = useState({
    width: widthVideo,
    height: 270,
    facingMode: "environment"
    // facingMode: { exact: "environment" }
  })
  // const [topImg, setTopImg] = useState();
  const [topHeight, setTopHeight] = useState();

  // const [imgMarkwater, setImgMarkWater] = useState('img/outline-fte.png');

  // const [widthBC, setWidthBC] = useState("100%")
  // const [bottomBC, setBottomBC] = useState("100px")

  useEffect(() => {
    if (images.length === 0) {
      setType("front")
    }

    if (images.length === 2) {
      setWidth(document.getElementById("content-video").offsetWidth)
      setShowWebcam(false)
    } else {
      setShowWebcam(true)
    }
  }, [images])

  useEffect(() => {
    type === 'front' ? setIne(0) : setIne(1)
  }, [type])

  const webcamRef = React.useRef(null);

  const handlerCapture = () => {

    setIne(() => !ine)
    setType(() => type === 'front' ? 'back' : 'front')

    const imageSrc = webcamRef.current.getScreenshot();
    const size = getSize(imageSrc);
    setImages([...images, {
      id: Math.random().toString(36).substring(2, 4 + 2),
      name: "photo" + (images.length + 1),
      ext: "png",
      src: imageSrc,
      size: size,
      type: type
    }
    ])
  };

  useEffect(() => {
    setTimeout(() => {
      let body = document.getElementById("page-content")
      document.getElementsByClassName("content")[0].style.width = body.offsetWidth + "px"
    }, 1000)
  }, [showWebcam])

  const handlerRemoveImage = (id) => {
    setImages(images.filter((a) => {
      if (a.id !== id) {
        return a
      } else {
        setType(a.type)
      }
    }
    ))
  }

  useEffect(() => {
    let videoContent = document.getElementById('content-video');
    let widthDocument = document.getElementById('page-content').offsetWidth
    let wbc = document.getElementsByClassName('col-12')[0].offsetWidth - 6
    if (widthDocument < 720 && showWebcam) {
      document.getElementById('content-video').style.width = "100%"
      // document.getElementById('ine-front').style.height = videoContent.offsetHeight + 'px'
      // setWidthBC(wbc + "px")
    }
  }, [])

  useEffect(() => resizeImg(), [])


  window.addEventListener("resize", () => {
    resizeImg()
  });

  const resizeImg = () => {
    let video = document.getElementById('content-video');
    setTopHeight(video.offsetHeight)
  }

  const getSize = (data) => {
    var stringLength = data.length - 'data:image/png;base64,'.length;
    var sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
    return Math.ceil(sizeInBytes / 1000);
  }

  const nextPage = () => {

    /* setCredential({
      "imagenes": images,
      "userFio": 'asdfasdfasdfasdf'
    })
    handlerChangeInfo(3)
    return false */

    faceio.enroll({
      "locale": "auto", // Default user locale
      // "payload": {
      /* The payload we want to associate with this particular user which is forwarded back to us upon future authentication of this user.*/
      // "whoami": 123456, // Dummy ID linked to this particular user
      // "email": "john.doe@example.com"
      // }
    }).then(userInfo => {
      // User Successfully Enrolled! 
      /* alert(
        `User Successfully Enrolled! Details:
        Unique Facial ID: ${userInfo.facialId}
        Enrollment Date: ${userInfo.timestamp}
        Gender: ${userInfo.details.gender}
        Age Approximation: ${userInfo.details.age}`
      ); */

      setCredential({
        "imagenes": images,
        "userFio": userInfo.facialId
      })

      // console.log(userInfo);
      // handle success, save the facial ID (userInfo.facialId), redirect to the dashboard...
    })
      .then(() => {
        handlerChangeInfo(3)
      })
      .catch(errCode => {
        console.log(errCode)
        // Something went wrong during enrollment, log the failure
        handleError(errCode);
        if (errCode === 13)
          faceio.restartSession();
      })
  }

  const handleError = (errCode) => {
    // Log all possible error codes during user interaction..
    // Refer to: https://faceio.net/integration-guide#error-codes
    // for a detailed overview when these errors are triggered.
    switch (errCode) {
      case 1:
        console.log("Access to the Camera stream was denied by the end user");
        break;
      case 2:
        console.log("No faces were detected during the enroll or authentication process");
        break;
      case 3:
        console.log("Unrecognized face on this application's Facial Index");
        break;
      case 4:
        console.log("Two or more faces were detected during the scan process");
        break;
      case 21:
        console.log("User enrolled previously (facial features already recorded). Cannot enroll again!");
        break;
      case 22:
        console.log("Minors are not allowed to enroll on this application!");
        break;
      case 5:
        console.log("Presentation (Spoof) Attack (PAD) detected during the scan process");
        break;
      case 6:
        console.log("Calculated Facial Vectors of the user being enrolled do not matches");
        break;
      case 8:
        console.log("Wrong PIN code supplied by the user being authenticated");
        break;
      case 9:
        console.log("Server side error");
        break;
      case 10:
        console.log("Your application is not allowed to perform the requested operation (eg. Invalid ID, Blocked, Paused, etc.). Refer to the FACEIO Console for additional information");
        break;
      case 11:
        console.log("Terms & Conditions set out by FACEIO/host application rejected by the end user");
        break;
      case 12:
        console.log("The FACEIO Widget could not be (or is being) injected onto the client DOM");
        break;
      case 13:
        console.log("Client session expired. The first promise was already fulfilled but the host application failed to act accordingly");
        break;
      case 14:
        console.log("Ongoing operation timed out (eg, Camera access permission, ToS accept delay, Face not yet detected, Server Reply, etc.)");
        break;
      case 15:
        console.log("Widget instantiation requests exceeded for freemium applications. Does not apply for upgraded applications");
        break;
      case 16:
        console.log("Origin or Referer HTTP request header is empty or missing");
        break;
      case 17:
        console.log("Domain origin is forbidden from instantiating fio.js");
        break;
      case 18:
        console.log("Country ISO-3166-1 Code is forbidden from instantiating fio.js");
        break;
      case 20:
        console.log("Another authentication or enrollment session is in progress");
        break;
      case 7:
      default:
        console.log("Error while establishing network connection with the target FACEIO processing node");
        break;
    }
  }

  return (
    <>
      <div className="px-4">
        <div className="row">
          <div className="col-12 text-left">
            Toma una <strong className="text-primary">foto</strong> de tu credencial de elector vigente (INE) por ambos lados:
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            {ine === 0 && showWebcam && <div className="watermark-ine-front" id="ine-front" style={{ height: topHeight }}></div>}
            {ine === 1 && showWebcam && <div className="watermark-ine-back" id="ine-back" style={{ height: topHeight }}></div>}
            {showWebcam &&
              <>
                {/* <div style={{
                  width: "100%",
                  height: topHeight,
                  float: "left",
                  position: "absolute",
                  top: topImg,
                  zIndex: 100,
                  backgroundImage: "url(" + imgMarkwater + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}></div> */}
                <Webcam
                  style={{
                    width: width
                  }}
                  id="content-video"
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoSettings}
                />
              </>
            }
          </div>
        </div>
        <div>
          <ul className="list-group mt-1">
            {images.map((image) =>
              <li className="list-group-item item-photo" key={image.id}>
                Foto - {image.type} ({image.size}kb)
                <span className="float-end">
                  <FontAwesomeIcon icon={faTimes} size='lg' className="text-danger" role="button" onClick={() => handlerRemoveImage(image.id)} />
                </span>
              </li>
            )}
          </ul>
        </div>
        <div className="mt-1">
          <button onClick={() => handlerCapture()}
            className="btn btn-light-info bs-button mt-3"
            style={{ width: "100%" }}>
            Tomar foto <FontAwesomeIcon icon={faFileCirclePlus} size='lg' />
          </button>
        </div>
        {images.length === 2 &&
          <div className="row mt-3">
            <div className="d-grid gap-2 mx-auto">
              <button className="btn btn-primary rounded-pill" id="btn-next" onClick={() => nextPage()}>Siguente</button>
            </div>
          </div>
        }
      </div>
    </>
  )
}