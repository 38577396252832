import React, { useState } from 'react';
import TutorContext from '../context/TutorContext';
import { Loading } from '../component/Loading';

const StateTutor = ({ children }) => {

  const [tutor, setTutor] = useState({});
  const [loading, setLoading] = useState(0)

    return (
      <TutorContext.Provider
        value={{
          tutor,
          setTutor,
          setLoading
        }}
      >{children}
      { loading ? <Loading /> : null }
      </TutorContext.Provider>
    );
  }

  export default StateTutor;