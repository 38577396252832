import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const ScreenResponse = ({ msg, icon, titleButton, action, color = 'text-primary' }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className='col-md-10'>
          <div className="card ">
            <div className="card-body">
              <div className="row">
                <div className="col-12 text-center">
                  <FontAwesomeIcon icon={icon} size="2x" className={color} />
                </div>
                <div className="col-12 mt-2 text-center">{msg}</div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="d-grid gap-2 mx-auto">
              <button className="btn btn-primary rounded-pill" onClick={action}>{titleButton}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
