import React, { useState } from 'react'
import StudentContext from '../context/StudentContext'

const StateStudent = ({ children }) => {

  const [students, setStudents] = useState([])

  return (
    <StudentContext.Provider
      value={{
        students,
        setStudents
      }}
    >{ children }
    </StudentContext.Provider>
  );
}

export default StateStudent;