export const Loading = () => {
    return (
        <>
            <div className="loading d-flex justify-content-center">
                <img
                    width={80}
                    height={80}
                    src="img/spinner.png"
                    alt="loading"
                />  
            </div>
        </>
    )
}

