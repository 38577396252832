import React, { useState } from 'react'
import FooterContext from '../context/FooterContext'

const StateFooter = ({ children }) => {

  const [footer, setFooter] = useState([0,0,0]);

  return (
    <FooterContext.Provider
      value={{
        footer,
        setFooter
      }}
    >{ children }</FooterContext.Provider>
  );
}

export default StateFooter;