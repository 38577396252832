import React, { useState, useEffect } from "react";
// import Popup from "reactjs-popup";
import { Toaster } from 'react-hot-toast';

import { Header } from "./component/Header";
import { Body } from "./component/Body";
import { Footer } from "./component/Footer";
// import { UploadSignature } from "./component/UploadSignature";

import "./App.css";
import "./css/sigCanvas.css";
import "./css/style.css";
import StateTutor from "./state/StateTutor";
import StateStudent from "./state/StateStudent";
import StateFooter from "./state/StateFooter";
import StateCredential from "./state/StateCredential";

function App() {

  const [withFooter, setWithFooter] = useState(670)
  
  const imageSrc = "img/app-header.png";

  useEffect(() => {
    const widthPageContent = document.getElementById('page-content').offsetWidth
    if(widthPageContent > 720) {
      setWithFooter(670)
    } else {
      setWithFooter(widthPageContent)
    }
  }, [])

  return (
    <>
      <StateTutor>
        <StateStudent>
          <StateFooter>
            <StateCredential>
              <div className="container" style={{ maxWidth: 720 }}>
                <div className="d-flex justify-content-center vh-100" id="page-content">
                  <div className="gb content">
                    <div className="header">
                      <Header imageSrc={imageSrc} />
                    </div>
                    <div className="body" id="body">
                      <Body />
                    </div>
                    <Footer widthFooter={withFooter} />
                  </div>
                </div>
              </div>
            </StateCredential>
          </StateFooter>
        </StateStudent>
      </StateTutor>
      <Toaster />
    </>
  );
}

export default App;