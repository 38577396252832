import React, { useState, useEffect, useContext } from "react"
import { ItemStudent } from "./ItemStudent"
import DatePicker, { registerLocale } from "react-datepicker"
import InputMask from "react-input-mask"
import toast from 'react-hot-toast'
import es from 'date-fns/locale/es'
import axios from "axios"
import "react-datepicker/dist/react-datepicker.css"
import TutorContext from "../context/TutorContext"
import FooterContext from "../context/FooterContext"
import StudentContext from "../context/StudentContext"
import { url } from '../utils/Constants';

export const Student = ({ handlerChangeInfo }) => {

  registerLocale('es', es)

  const [nameStudent, setNameStudent] = useState('')
  const [firstLastnameStudent, setFirstLastnameStudent] = useState('')
  const [secondLastnameStudent, setSecondLastnameStudent] = useState('')
  const [gradeStudent, setGradeStudent] = useState('')
  const [levelStudent, setLevelStudent] = useState('')
  const [birthday, setBirthday] = useState()
  const [grades, setGrades] = useState([])
  const [gradeName, setGradeName] = useState([])
  const [levels, setLevels] = useState([])
  const [levelName, setLevelName] = useState([])
  const [data, setData] = useState([])
  const [next, setNext] = useState(0)
  const { setLoading } = useContext(TutorContext)

  const { footer, setFooter } = useContext(FooterContext)
  const { setStudents} = useContext(StudentContext)

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/grados/`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setGrades(response.data)
    }).catch((error) => {
      console.log(error)
      setGrades([])
    })
  }, [])

  const handlerChangeGrade = (event) => {
    const value = event.target.value
    if(value) {
      const index = event.nativeEvent.target.selectedIndex
      const name = event.nativeEvent.target[index].text
      setGradeName(name);
      setGradeStudent(value)
      getLevels(value)
    }
  }

  useEffect(() => {
    if(data.length > 0) {
      setNext(1)
      setFooter([1,1,0])
    } else {
      setNext(0)
      setFooter([1,0,0])
    }
  },[data])

  const getLevels = (value) => {
    if(!value) {
      setLevels([])
      return false
    }
    setLoading(1)
    axios({
      method: 'get',
      url: `${url}/niveles/grados/${value}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setLevels(response.data)
      setLoading(0)
    }).catch((error) => {
      console.log(error)
      setLevels([])
      setLoading(0)
    })
  }

  const handlerChangeLevel = (event) => {
      const value = event.target.value
      const index = event.nativeEvent.target.selectedIndex
      const name = event.nativeEvent.target[index].text
      setLevelName(name)
      setLevelStudent(value)
  }

  const handlerFormatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const handlerAddStudent = () => {
    if (!nameStudent) {
      toast.error('Debes ingresar el nombre...', { duration: 2000 })
      return false
    }

    if (!firstLastnameStudent) {
      toast.error('Debes el apellido paterno...', { duration: 2000 })
      return false
    }

    if (!secondLastnameStudent) {
      toast.error('Debes el apellido materno...', { duration: 2000 })
      return false
    }

    if (!gradeStudent) {
      toast.error('Debes seleccionar el nivel académico...', { duration: 2000 })
      return false
    }

    if (!levelStudent) {
      toast.error('Debes seleccionar el grado...', { duration: 2000 })
      return false
    }

    if (!birthday) {
      toast.error('Debes seleccionar la fecha de nacimiento...', { duration: 2000 })
      return false
    }

    setData([
      ...data,
      { "id": (data.length + 1),
        "names": nameStudent,
        "firstLastname": firstLastnameStudent,
        "secondLastname": secondLastnameStudent,
        "grade": gradeStudent,
        "gradeName": gradeName,
        "level": levelStudent,
        "levelName": levelName,
        "birthday": birthday,
        "birthdayFormat": handlerFormatDate(birthday)
      }
    ]);

    setNameStudent('')
    setFirstLastnameStudent('')
    setSecondLastnameStudent('')
    setGradeStudent('')
    setLevelStudent('')
    setBirthday('')
  }

  const handlerRemoveStudent = (value) => {
    setData(data.filter(a => a.id !== value))
  }

  const handlerNextPage = () => {
    setStudents(data)
    handlerChangeInfo(2)
  }

  return (
    <>
      <div className="px-4">
        <div className="row">
          <div className="col-12 text-left">
            A continuación <strong className="text-primary">ingresa,</strong> los datos de los estudiantes que iniciarán el ciclo escolar correspondiente:
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <InputMask value={ nameStudent } onChange={(event) => { setNameStudent(event.target.value) }} className="input-general text-capitalize" placeholder="Nombre(s)" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mt-2">
            <InputMask value={ firstLastnameStudent } onChange={ (event) => { setFirstLastnameStudent(event.target.value) }} className="input-general text-capitalize" placeholder="Apellidos Paterno" />
          </div>

          <div className="col-md-6 mt-2">
            <InputMask value={ secondLastnameStudent } onChange={ (event) => { setSecondLastnameStudent(event.target.value) }} className="input-general text-capitalize" placeholder="Apellidos Materno" />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-2 pr-1">
            <select className="input-general content-select" value={gradeStudent} onChange={(event) => { handlerChangeGrade(event) }}>
              <option value>Nivel Académico</option>
              {grades.map(grade => (<option key={grade.id} value={grade.id}>{ grade.nombre }</option>))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mt-2">
            <select value={ levelStudent } onChange={ (event) => { handlerChangeLevel(event) }} className="input-general">
              <option value>Grado</option>
              {levels.map(level => (<option key={level.id} value={level.id}>{ level.nombre }</option>))}
            </select>
          </div>

          <div className="col-md-6 mt-2">
            <DatePicker
              locale={es}
              placeholderText="DD/MM/AAAA"
              selected={birthday}
              onChange={(date) => setBirthday(date)}
              className="text-center input-general"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown={true}
              showYearDropdown={true}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="d-grid gap-2 mx-auto">
            <button className="btn btn-primary rounded-pill" type="button" onClick={ () => { handlerAddStudent() }}>Agregar</button>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12">
            <div className="list-items">
              { data.map( data => <ItemStudent key={ data.id } data={ data } handlerRemoveStudent={ () => handlerRemoveStudent(data.id) }/> ) }
            </div>
          </div>
        </div>
        { next === 1 &&
          <div className="row mt-2">
            <div className="d-grid gap-2 mx-auto">
              <button className="btn btn-primary rounded-pill" onClick={ () => { handlerNextPage() }}>Siguente</button>
            </div>
          </div>
        }

      </div>
    </>
  )
}
