import { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faUser, faSquareCheck, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import FooterContext from '../context/FooterContext';

export const Footer = ({ widthFooter }) => {

  const [width, setWidth] = useState(widthFooter);

  const { footer } = useContext(FooterContext);

  const resizeWidth = () => {
    let widthDocument = document.getElementById('page-content').offsetWidth
    if (widthDocument < 720) {
      setWidth(widthDocument)
    }
  }

  window.addEventListener("resize", () => resizeWidth())

  useEffect(() => resizeWidth(), [])

  return (
    <>
      <div className="footer" style={{ width: width }} id="footer">
        <div className="row text-center">
          <div className="col-3">
            <FontAwesomeIcon icon={faAddressCard} size='lg' />
            {footer[0] === 1 && <FontAwesomeIcon icon={faCheckCircle} size='lg' className='icon-fa' color='#24E55A' />}
          </div>
          <div className="col">
            <div className='d-flex justify-content-between'>
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[0] === 0 ? '#ffffff98' : null} />
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[0] === 0 ? '#ffffff98' : null} />
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[0] === 0 ? '#ffffff98' : null} />
            </div>
          </div>
          <div className="col-3">
            <FontAwesomeIcon icon={faUser} size='lg' color={footer[1] === 0 ? '#ffffff98' : null} />
            {footer[1] === 1 && <FontAwesomeIcon icon={faCheckCircle} size='lg' className='icon-fa' color='#24E55A' />}
          </div>
          <div className="col">
            <div className='d-flex justify-content-between'>
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[2] === 0 ? '#ffffff98' : null} />
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[2] === 0 ? '#ffffff98' : null} />
              <FontAwesomeIcon icon={faCircle} size='xs' color={footer[2] === 0 ? '#ffffff98' : null} />
            </div>
          </div>
          <div className="col-3">
            <FontAwesomeIcon icon={faSquareCheck} size='lg' color={footer[2] === 0 ? '#ffffff98' : null} />
            {footer[2] === 1 && <FontAwesomeIcon icon={faCheckCircle} size='lg' className='icon-fa' color='#24E55A' />}
          </div>
        </div>
      </div>
    </>
  );
}