import { useState, useRef, useEffect, useContext } from "react"
import SignaturePad from "react-signature-canvas"
import axios from "axios"
import CredentialContext from "../context/CredentialContext"
import TutorContext from "../context/TutorContext"
import StudentContext from "../context/StudentContext"
import { ScreenResponse } from "./ScreenResponse";
import { faCircleXmark, faCircleCheck, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FooterContext from "../context/FooterContext"
import { url } from '../utils/Constants';

export const UploadSignature = ({ widthFirm, heightFirm }) => {

  const { tutor } = useContext(TutorContext)
  const { students } = useContext(StudentContext)
  const { credential } = useContext(CredentialContext)
  const { setFooter } = useContext(FooterContext)

  const [imageURL, setImageURL] = useState(null)
  const [wf, setWF] = useState(widthFirm)
  const [hf, setHF] = useState(heightFirm)
  const [response, setResponse] = useState(null)
  const [download, setDownload] = useState(false)
  const [contract, setContrat] = useState(null)


  const sigCanvas = useRef({})

  const clear = () => {
    sigCanvas.current.clear()
    setImageURL(null)
  }

  const generatePDF = (id) => {
    setContrat(id)
    axios.get(`${url}/contratos/generate-pdf/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setDownload(true)
        }
      })
  }

  const save = () => {

    setImageURL(() => sigCanvas.current.toDataURL())

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*'
    }

    const body = {
      "tutor": tutor,
      "estudiantes": students,
      "imagenes": credential.imagenes,
      "userFio": credential.userFio,
      "firma": sigCanvas.current.toDataURL()
    }

    setTimeout(() => {
      axios.post(`${url}/contratos/receive-data`, body, headers)
        .then((response) => {
          if (response.status === 200) {
            generatePDF(response.data.id);
            setFooter([1, 1, 1])
            setResponse(<div className="mt-5">
              <ScreenResponse msg="¡Tu registro se realizó correctamente!" icon={faCircleCheck} action={() => window.location.reload()} titleButton="Finalizar Sesión" />
            </div>)
          } else {
            setResponse(<div className="mt-5">
              <ScreenResponse msg="Tu registro no se realizó correctamente" icon={faCircleXmark} action={() => window.location.reload()} titleButton="Empezar de nuevo" color="text-danger" />
            </div>)
          }
        })
        .catch((error) => {
          console.log(error)
          setResponse(<div className="mt-5">
            <ScreenResponse msg="Tu registro no se realizó correctamente" icon={faCircleXmark} action={() => window.location.reload()} titleButton="Empezar de nuevo" color="text-danger" />
          </div>)
        })
    }, 1000)

  }

  useEffect(() => resizeFirm())

  window.addEventListener("resize", () => {
    let widthDocument = document.getElementById('page-content').offsetWidth;
    if (widthDocument < 720) {
      setWF(widthDocument - 30)
      setHF(250)
    } else {
      setWF(widthFirm)
      setHF(heightFirm)
    }
  });

  const resizeFirm = () => {
    let widthDocument = document.getElementById('page-content').offsetWidth;
    if (widthDocument < 720) {
      setWF(widthDocument - 30)
      setHF(250)
    } else {
      setWF(widthFirm)
      setHF(heightFirm)
    }
  }

  return (
    <>
      {download &&
        <div className="row mb-4">
          <div className="col-10 d-grid gap-2 mx-auto mt-2">
            <button className="btn btn-outline-primary rounded-pill" onClick={() => { window.open(`${url}/contratos/download-pdf/${contract}`, "_blank", "noreferrer"); }}>Descargar PDF <FontAwesomeIcon icon={faFilePdf} /></button>
          </div>
        </div>
      }
      {response ? response :
        <div>
          <div className="row">
            <div className="col-12 text-left">
              Dibuja tu firma y da click en <strong className="text-primary">Aceptar</strong>:
            </div>
            <div className="col-12">
              <SignaturePad
                id="firm"
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas",
                  width: wf,
                  height: hf
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-grid gap-2 mx-auto mt-2">
              <button className="btn btn-primary rounded-pill" onClick={save}>Aceptar</button>
            </div>
            <div className="col-md-6 d-grid gap-2 mx-auto mt-2">
              <button className="btn btn-outline-primary rounded-pill" onClick={clear}>Reintentar</button>
            </div>
          </div>
        </div>
      }
    </>
  )
}