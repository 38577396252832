import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIdCard, faTrash } from '@fortawesome/free-solid-svg-icons'

export const ItemStudent = ({ data, handlerRemoveStudent }) => {
  
  return (
    <>
      <div className="card mb-2 card-student">
        <div className="card-body">
          <div className="row">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faIdCard} size="xl" className="text-primary" />
            </div>
            <div className="col-9">
              <div className="fs-5">{ data.names + ' ' + data.firstLastname + ' ' + data.secondLastname }</div>
              <div className="fs-6"><small>{ data.levelName }</small></div>
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faTrash} className="text-danger" role="button" onClick={ handlerRemoveStudent }/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}