import React, { useState } from 'react'
import CredentialContext from '../context/CredentialContext'

const StateCredential = ({ children }) => {
    
    const [credential, setCredential] = useState([])

    return (
        <CredentialContext.Provider
            value={{ 
                credential, 
                setCredential 
            }}
        >{ children }
        </CredentialContext.Provider>
    )
}

export default StateCredential;