import { useEffect, useState } from "react";
import { Tutor } from "./Tutor";
import { Student } from "./Student";
import { Credential } from "./Credential";
import { UploadSignature } from "./UploadSignature";

export const Body = () => {

  const [info, setInfo] = useState(0)
  const [heightMark, setHeightMark] = useState(273)

  switch (info) {
    case 0: break;
    case 1: break;
    default: break;
  }

  const handlerChangeInfo = (value) => {
    setInfo(value)
  }

  useEffect(() => {
    let wd = document.body.offsetWidth
    if(wd <= 720) {
      setHeightMark(150)
    } else {
      setHeightMark(273)
    }
  })

  return (

    <>
      {info === 0 && <Tutor handlerChangeInfo={handlerChangeInfo} />}
      {info === 1 && <Student handlerChangeInfo={handlerChangeInfo} />}
      {info === 2 && <Credential handlerChangeInfo={handlerChangeInfo} widthVideo={640} heightMark={ heightMark } /> }
      {info === 3 && <UploadSignature widthFirm={670} heigthFirm={300} /> }
    </>
  )
}