import React, { useState, useContext, useEffect } from "react"
import InputMask from "react-input-mask"
import toast from 'react-hot-toast'
import FooterContext from "../context/FooterContext"
import TutorContext from "../context/TutorContext"
import axios from "axios"
import { url } from '../utils/Constants';

export const Tutor = ({ handlerChangeInfo }) => {

  const [nameTutor, setNameTutor] = useState('')
  const [lastnameFirstTutor, setLastnameFirstTutor] = useState('')
  const [lastnameSecondTutor, setLasnameSecondTutor] = useState('')
  const [addressTutor, setAddressTutor] = useState('')
  const [unitedStateTutor, setUnitedStateTutor] = useState('')
  const [cityTutor, setCityTutor] = useState('')
  const [zipcodeTutor, setZipcodeTutor] = useState('')
  const [phoneTutor, setPhoneTutor] = useState('')
  const [parentTutor, setParentTutor] = useState('')

  const { setFooter } = useContext(FooterContext)
  const { setTutor, setLoading } = useContext(TutorContext)

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [parentescos, setParentescos] = useState([])

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/estados/`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setStates(response.data)
      /* console.log(response.status)
      console.log(response.statusText)
      console.log(response.headers)
      console.log(response.config) */
    }).catch((error) => {
      console.log(error)
      setStates([])
    })
  }, [])

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/parentescos/`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setParentescos(response.data)
      /* console.log(response.status)
      console.log(response.statusText)
      console.log(response.headers)
      console.log(response.config) */
    }).catch((error) => {
      console.log(error)
      setStates([])
    })
  }, [])

  const validate = () => {
    if (!nameTutor) {
      toast.error('Debes ingresar el nombre...', { duration: 2000 })
      return false
    }

    if (!lastnameFirstTutor) {
      toast.error('Debes ingresar el apellido paterno...', { duration: 2000 })
      return false
    }

    if (!lastnameSecondTutor) {
      toast.error('Debes ingresar el apellido materno...', { duration: 2000 })
      return false
    }

    if (!addressTutor) {
      toast.error('Debes ingresar la dirección...', { duration: 2000 })
      return false
    }

    if (!unitedStateTutor) {
      toast.error('Debes seleccionar el estado...', { duration: 2000 })
      return false
    }

    if (!cityTutor) {
      toast.error('Debes seleccionar la ciudad...', { duration: 2000 })
      return false
    }

    if (!zipcodeTutor) {
      toast.error('Debes ingresar el código postal...', { duration: 2000 })
      return false
    }

    if (!phoneTutor) {
      toast.error('Debes ingresar el teléfono...', { duration: 2000 })
      return false
    }

    if (!parentTutor) {
      toast.error('Debes seleccionar el parentesco...', { duration: 2000 })
      return false
    }

    setTutor({
      'name': nameTutor,
      'firstLastname': lastnameFirstTutor,
      'secondLastname': lastnameSecondTutor,
      'address': addressTutor,
      'unitedState': unitedStateTutor,
      'city': cityTutor,
      'zipcode': zipcodeTutor,
      'phone': phoneTutor,
      'parent': parentTutor
    })
    
    setFooter([1, 0, 0])

    handlerChangeInfo(1)
  }

  const handlerChangeState = (value) => {
    setUnitedStateTutor(value)
    getCities(value)
  }

  const getCities = (value) => {

    if(!value) {
      setCities([])
      return false
    }
    setLoading(1)
    axios({
      method: 'get',
      url: `${url}/municipios/${value}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setCities(response.data)
      setLoading(0)
    }).catch((error) => {
      console.log(error)
      setCities([])
      setLoading(0)
    })
  }

return (
  <>
    <div className="p-2">
      <div className="row">
        <div className="col-12 text-left">
          Bienvenid@ a <strong className="text-primary">Instituto Escolar,</strong> para continuar el proceso de registro <strong className="text-primary">ingresa</strong> los siguientes datos:
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <InputMask value={nameTutor} onChange={(event) => { setNameTutor(event.target.value) }} className="input-general text-capitalize" placeholder="Nombre(s)" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-2">
          <input value={lastnameFirstTutor} onChange={(event) => { setLastnameFirstTutor(event.target.value) }} type="text" className="input-general text-capitalize" placeholder="Apellido Paterno" />
        </div>

        <div className="col-md-6 mt-2">
          <input value={lastnameSecondTutor} onChange={(event) => { setLasnameSecondTutor(event.target.value) }} type="text" className="input-general text-capitalize" placeholder="Apellido Materno" />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <input value={addressTutor} onChange={(event) => { setAddressTutor(event.target.value) }} type="text" className="input-general text-capitalize" placeholder="Dirección línea 1" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-2 pr-1">
          <select className="input-general content-select" value={unitedStateTutor} onChange={(event) => { handlerChangeState(event.target.value)}}>
            <option value>Estado</option>
            {states.map(state => (<option key={state.id} value={state.estado_id}>{ state.nombre }</option>))}
          </select>
        </div>

        <div className="col-md-6 mt-2 pl-1">
          <select className="input-general content-select" value={cityTutor} onChange={(event) => { setCityTutor(event.target.value) }}>
            <option value>Ciudad</option>
            {cities.map(city => (<option key={city.id} value={city.municipio_id}>{ city.nombre }</option>))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-2 pr-1">
          <InputMask mask="99999" className="input-general content-select" placeholder="C.P." value={zipcodeTutor} onChange={(event) => { setZipcodeTutor(event.target.value) }} />
        </div>

        <div className="col-md-6 mt-2 pl-1">
          <InputMask mask="99 99 99 99 99" className="input-general content-select" placeholder="Teléfono" value={phoneTutor} onChange={(event) => { setPhoneTutor(event.target.value) }} />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <select className="input-general" value={parentTutor} onChange={(event) => { setParentTutor(event.target.value) }}>
            <option value>Parentesco familiar</option>
            {parentescos.map(parentesco => (<option key={parentesco.id} value={parentesco.nombre}>{ parentesco.nombre }</option>))}
          </select>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12"></div>
        <div className="d-grid gap-2 mx-auto">
          <button className="btn btn-primary rounded-pill" type="button" onClick={() => { validate() }}>Siguiente</button>
        </div>
      </div>
    </div>
  </>
)
}